import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { forgotPwAPIURL, guestUser } from '@/components/Utility/Base'
import CommonFunctions from '@/components/Utility/Common'
import { admZubPth } from '@/components/Utility/aXdYmZ'
import { store } from '@/store/index'
import SysUser from '@/types/SysUser'
import SysMember from '@/types/SysMember'
import AuthService from '@/services/AuthService'
import MembersDataService from '@/services/MembersDataService'
import UsersDataService from '@/services/UsersDataService'
import axios from 'axios'
import LoadingButton from '@/components/Frontend/LoadingButton/index.vue'

type dataReturnType = { userName: string; userPassword: string; error: any; }

@Options({
  components: {
    LoadingButton
  }
})
export default class login extends Vue {
  private userName = ''
  private userPassword = ''
  forgottenPasswordModal = false
  actionConfirmationModal = false
  actionConfirmationMessage = ''
  emailString = ''
  error: any = null
  tempUserData: SysUser[] = []
  tempMemberData: SysMember[] = []
  loadingStatus = false

  readonly name: string = 'Login'
  $Message: any
  data (): dataReturnType {
    return {
      userName: this.userName,
      userPassword: this.userPassword,
      error: this.error
    }
  }

  @Watch('forgottenPasswordModal')
  onPassModalChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      this.emailString = ''
    }
  }

  @Watch('actionConfirmationModal')
  onActionModalChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      this.actionConfirmationMessage = ''
    }
  }

  public convertUserNameToSlug (id: number, userName: string) : string {
    return id.toString() + '-' + CommonFunctions.slugify(userName)
  }

  public async login () {
    this.loadingStatus = true
    let loginResponse: any = {}

    if (this.userName.length === 0) {
      this.loadingStatus = false
      return this.$Message.danger({ text: 'Der mangler er brugernavn' })
    }
    if (this.userPassword.length === 0) {
      this.loadingStatus = false
      return this.$Message.danger({ text: 'Der mangler er adgangskode' })
    }

    // Try to login with the given credentials.
    try {
      loginResponse = await AuthService.login(this.userName.trim(), this.userPassword)
      // console.log('[Login:login()] loginResponse = ' + JSON.stringify(loginResponse))
      if (this.userName.trim() === guestUser) {
        localStorage.setItem('logintype', false.toString())
      } else {
        localStorage.setItem('logintype', true.toString())
        store.commit('setLoginSysStateLogintype', true)
        store.commit('setLoginSysStateStatus', true)
      }
    } catch (err) {
      // this.error = err
      this.$Message.danger({ text: 'Brugernavn eller adgangskode er forkert' })
      return
    } finally {
      this.loadingStatus = false
    }

    MembersDataService.getAll('', null, ('user_id.id=' + Number(loginResponse.user.id).toString()))
      .then((response) => {
        this.tempMemberData = response.data
        if (this.tempMemberData.length > 1) {
          return this.$Message.danger({ text: 'Fejl: Flere med samme brugernavn' })
        }
        if (this.tempMemberData[0].user_id.usrgroup === 2) {
          return this.$router.push({ name: 'AdminHome', path: `${admZubPth}` })
        }
        if (this.tempMemberData.length === 0) {
          return this.$Message.warning({ text: 'Fejl: Ingen med det brugernavn' })
        }
        console.log(this.tempMemberData[0])
        return this.$router.push({ name: 'MemberPortalMain', params: { userSlug: this.convertUserNameToSlug(Number(this.tempMemberData[0].id), this.tempMemberData[0].user_id.username) } })
      })
      .catch((err) => {
        this.error = err
      })
      .finally(() => {
        this.loadingStatus = false
      })
  }

  public async newPasswordRequest () : Promise<void> {
    if (this.emailString !== undefined && this.emailString !== null && this.emailString.toString().trim().length >= 3 && this.emailString.toString().includes('@')) {
      axios.post(forgotPwAPIURL, {
        email: this.emailString.toString().trim()
      })
        .then((response) => {
          // console.log('Email with link sent: ' + response.statusText)
          this.forgottenPasswordModal = false
          this.actionConfirmationModal = true
          this.actionConfirmationMessage = 'Vi har sendt en email til dig med et link hvor du kan ændre din adgangskode'
        })
        .catch((err) => {
          console.log(err)
          this.actionConfirmationModal = true
          this.actionConfirmationMessage = 'Der opstod en fejl: ' + err
        })
    }
  }
}
